import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { ChipsFilter, ChipsInput, ChipsLiveExample, ChipsSmall } from "../../examples/components/Chips";
import chipImage from "../../assets/img/headshot.png"; // relative path to image
export const query = graphql`
  query ChipQuery {
    Chip: componentMetadata(displayName: { eq: "Chip" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  Chips are used to add information, make selections or filter content. Chips
  should appear dynamically as a group of multiple interactive elements.
  <p>
    To Use Chip, add <code>&lt;Chip&gt;</code> component with{" "}
    <code>variant</code> like,
    <ul>
      <li>solid</li>
      <li>outline</li>
      <li>reverse</li>
    </ul>
  </p>
  Use <code>label</code> for adding text and <code>avatar</code>,{" "}
  <code> icon </code> props for adding thumbnail & icon respectively.
    </Overview>
    <CodeBlock title="Input Chips" code={ChipsInput} scope={{
      chipImage
    }} mdxType="CodeBlock">
  For input chips use the prop <code>type</code> set to <code>input</code>.
    </CodeBlock>
    <CodeBlock title="Live Example" scope={{
      chipImage
    }} noInline={true} code={ChipsLiveExample} mdxType="CodeBlock">
  Chips with the <code>onClose</code> or <code>dismissible </code> prop defined
  will display a delete icon and can also use prop <code>show</code> to control
  the visibility of the chip.
    </CodeBlock>
    <CodeBlock title="Filter Chips" code={ChipsFilter} mdxType="CodeBlock">
  For Filter Chips, use the <code>icon</code> prop to define the icon.
    </CodeBlock>
    <CodeBlock title="Small Chips" code={ChipsSmall} scope={{
      chipImage
    }} mdxType="CodeBlock">
  To use a small chip, use <code>size</code> prop and use <code>closeIcon</code>{" "}
  props for custom close icon.
    </CodeBlock>
    <LinkedHeading h="2" className="h1" id="chip-api" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.Chip} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      